.logout-btn {
    margin: 0 auto;
    display: block !important;
}

.logo {
    width: 50px;
}

#cartcount {
    font-size: 10px;
    background-color: red;
    color: white;
    padding: 2px 4px;
    vertical-align: top;
    margin-left: -8px;
    border-radius: 50%;
    margin-top: -5px;
}

.badge {
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 9px;
}

.fa-shopping-cart {
    font-size: 20px;
}

.notifications-container {
    padding: 20px;
    max-height: 200px;
    overflow-y: scroll;
    border: 1px solid gray;
    background-color: white;
    width: 200px;
    z-index: 99;
}

.notification-read {
    background-color: lightblue;
}

.notification-unread {
    background-color: lightcoral;
}
.fas[data-count] {
    position: relative;
}

.fa-bell {
    font-size: 1.5em;
}

.fas[data-count]:after {
    position: absolute;
    right: -0.75em;
    top: -0.75em;
    content: attr(data-count);
    padding: 0.5em;
    border-radius: 10em;
    line-height: 0.9em;
    color: white;
    background-color: red;
    text-align: center;
    min-width: 2em;
    font: bold 0.4em sans-serif;
}
