.fa-plus-circle,
.fa-minus-circle {
    cursor: pointer;
    display: inline-block;
}

.fa-plus-circle {
    margin-left: 5px;
}

.fa-minus-circle {
    margin-right: 5px;
}

.table-responsive-sm {
    max-height: 70vh;
    overflow: scroll;
}
