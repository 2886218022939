.product__carousel--image {
    max-width: 100%;
    object-fit: cover;
    max-height: 500px;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
    display: none !important;
}
