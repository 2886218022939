.images-preview-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100px, 100%), 1fr));
    gap: 10px;
    margin-top: 40px;
}

.image-preview {
    width: 100px;
    display: inline-block;
    position: relative;
}

.image-preview img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.image-preview i,
.remove-img-spinner {
    position: absolute;
    top: -12px;
    left: -12px;
    font-size: 20px;
    cursor: pointer;
}

.image-preview i:hover {
    color: orange;
}

.new-product__image--container {
    background-image: url(https://images.unsplash.com/photo-1652773899966-583e9d2f2fb0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzR8fHRlY2h8ZW58MHwxfDB8d2hpdGV8&auto=format&fit=crop&w=800&q=60);
    background-size: cover;
    background-position: center;
    height: 100vh;
}
